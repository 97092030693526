import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogNotification from '@/components/Dialog/DialogNotification/DialogNotification.vue';
import DialogCreateEditNotification from '../../Dialog/DialogCreateEditNotification/DialogCreateEditNotification.vue';
import Snackbar from "@/components/Snackbar";
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
    name: 'TableNotifications',
    data() {
      return this.initialState();
    },

    mixins: [
      AuthMixin,
      RailMixin,
      DataMixin,
    ],

    components:{
      EmptyStateNoData,
      DialogConfirmation,
      DialogNotification,
      DialogCreateEditNotification,
      Snackbar,
      UserCard
    },

    computed: {
      ...mapGetters({
        notifications: 'notifications/notifications',
        notificationsTotal: 'notifications/notificationsTotal',
        selectedNotification: 'notifications/selectedNotification',
        loading: 'notifications/loading',
        loadingUsers: 'users/loading',
        error: 'notifications/error',
        humanError: 'notifications/humanError',
        groups: 'groups/groups',
        users: 'users/users',
      }),

      optionsComputed: {
        get: function () {
          return this.options;
        },
        set: function (newValue) {
            if (!this._.isEqual(this.options, newValue)) {
                this.options = newValue;
            }
        }
      }
    },

    watch: {
      options: {
        handler() {
          this.fetchData();
        },
        deep: true,
        immediate: true,
      },
    },

    props: {
      actions:{
        type: Function,
        required: false,
        default: ()=> {}
      },
      filters: { type: Object, required: false, default: () => { }},
      isUser: { type: Boolean, required: false, default: false},
    },

    methods:{
      initialState() {
        let headers = [
          { text: 'Criado em', value: 'created_at', sortable: false, width: '5%' },
          { text: 'Título', value: 'title', sortable: true ,  width: '25%'},
          { text: 'Mensagem', value: 'description', sortable: false , width: '40%'},
          //{ text: 'Destino', value: 'destination', sortable: false },
          { text: 'Criado por', value: 'created_by', sortable: false, width: '20%'},
          { text: 'Ações', value: 'action', sortable: false , width: '10%'},
        ];

        if (this.isUser) {
          headers = headers.filter(header =>
            ['Título', 'Mensagem', 'Link', 'Ações'].includes(header.text)
          );
        }

        return {
          dialogues: {
            viewNotification: false,
            removeNotificationDialog: false,
            editNotification: false
          },
          idToDelete: null,
          notification: {},
          headers,
          idToDelete: null,
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
          search: null,
          options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: true,
            multiSort: true
          },
        }
      },

      getUserName(id) {
        let user = this.users.find(user => {
          return user.id == id;
        });
        return user ? user.name : (
          this.loadingUsers ? 'A Carregar...' : 'Jogador não encontrado');
      },

      getUserImgCutout(id) {
        let user = this.users.find(user => {
          return user.id == id;
        });
        return user ? user.img_cutout : '';
      },

      openDialogRemoveConfirmation(id){
        this.dialogues.removeNotificationDialog = true
        this.idToDelete = id
      },

      dialogConfirmationCancel(){
        this.dialogues.removeNotificationDialog = false
      },

      async deleteNotification(){
        let payload = {
          id:this.idToDelete,
        }
        let result = await this.$store.dispatch('notifications/remove', payload);

        this.dialogues.removeNotificationDialog = false

        if (result === true) {
          // Show success snackbar
          this.$store.dispatch('UI/showSnackbar', {
              message: 'Notificação removido com sucesso.',
              color: 'success'
          });

          this.$store.dispatch('notifications/getNotifications', payload);
          this.closeDialog();
        }
      },

      closeDialogNotification(){
        this.dialogues.viewNotification = false;
      },

      closeDialogCreateEditNotification(){
        this.dialogues.editNotification = false;
      },

      setActiveItem(item) {
        if (item) {
          this.$store.commit(
            'notifications/setSelectedNotification',
            item
          );
        }
      },

      async dialogViewNotification(item) {
        this.dialogues.viewNotification = true
        this.notification = item
      },

      async dialogEditNotification(item) {
        this.dialogues.editNotification = true
        this.notification = item
      },

      async fetchData() {
        if (_.isEmpty(this.users)) {
          let payload = this.formulateUsersAllUrlParameters();
          await this.$store.dispatch('users/get', payload);
        }

        let payload = {
          params: {
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          }
        };
        Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));
        this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);
        await this.$store.dispatch('notifications/getNotifications', payload);
      },
    }
  };
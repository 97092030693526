import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import CardStepperResume from '@/components/CardStepper/CardStepperResume/CardStepperResume.vue';
import CardStepperNetwork from '@/components/CardStepper/CardStepperNetwork/CardStepperNetwork.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
  name: 'CardRequestDepositUser',
  
  components: {
    CardStepper,
    CardStepperNotes,
    CardStepperResume,
    CardStepperNetwork,
    NicknameDropdown,
    AlertError,
  },

  mixins: [
    RailMixin,
  ],

  computed: {
    ...mapGetters({
      wallets: 'railWallets/wallets',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      selectedRequest: 'railRequests/selectedRequest',
      networks: 'networks/networks',
    }),

    activeCurrencies() {
      let currencies = [];

      if (this.$refs.cardStepperNetwork) {
        // Get all currency codes from CardStepperNetwork selection
        this.$refs.cardStepperNetwork.form.nicknames.forEach(e => {
          currencies.push(e.currency.code);
        });
      }

      // Remove duplicates
      currencies = this._.uniq(currencies);

      // Output
      return currencies;
    },

    formWallets() {
      let output = [];

      if (!this._.isEmpty(this.activeCurrencies)) {
        this.activeCurrencies.forEach(element => {

          let currency = this._.find(Vue.prototype.$currencies, ['code', element]);

          output.push({
            code: currency.code,
            symbol: currency.symbol,
            name: currency.name,
            total: this.$refs.cardStepperNetwork.getTotalBalanceByCurrency(currency.symbol),
            nickname: null,
          })
        })
      }

      this.form.syntheticWallets = output;

      return output;
    },
  },

  created() {
    return this.fetchData();
  },

  data() {
    return this.initialState();
  },

  methods: {
    /**
     * Validates a specific form step.
     * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
     * 
     * @param {Int} step Identifier of the form step to validate.
     * @returns {Void}
     */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {

        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );

        // clean errors if exists
        if (this.error) {
          this.$store.dispatch('railRequests/clearError');
        }
      }
    },

    async fetchData() {
      if (this._.isEmpty(this.wallets)) {
        await this.$store.dispatch('railWallets/getWallets');
      }

      await this.$store.dispatch('networks/get');
    },

    getSyntheticDropdownRules() {
      let output = true;
      let mapWallets = this.form.syntheticWallets.map(e => e.nickname);

      if (mapWallets.includes(null)) {
        output = false;
      }

      return output;
    },
    
    initialState() {
      return {
        cardTitles: [
          'Selecionar salas',
          'Selecionar carteiras',
          'Resumo'
        ],
        formValidations: [
          false,
          false,
          true,
        ],
        form: {
          syntheticWallets: [],
          observation: null,
          walletDollar: null,
          walletEuro: null,
          balance: null,
          nicknames: [],
          gyazoLink: null,
          fileName: []
        },
        rules: {
          cardStepperNetwork: [
            v => (v && v.length > 0) || 'Required!',
          ],
          walletDollar: [
            v => (v && !!v || !this.activeCurrencies.includes('USD')) || 'Required!',
          ],
          walletEuro: [
            v => (v && !!v || !this.activeCurrencies.includes('EUR')) || 'Required!',
          ],
        }
      }
    },

    /**
     * Retrieve fields from CardStepperNotes component
     */
    getInfoFromCardStepperNotes(payload) {
      this.form.gyazoLink = payload.gyazoLink;
      this.form.fileName = payload.filename;
    },

    /**
     * Show wallet name based on network currency code
     */
    showSelectedWalletByNetworkCurrency(currency) {
      let output = this._.find(this.formWallets, ['code', currency]);
      return output.nickname.id;
    },

    async submit() {
      // Validate
      if (!this.$refs.formBankrollIncrementChild.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
       * Formulate payload
       */
      let payload = new FormData();
      
      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.append('observation[url]', this.form.gyazoLink);
      }

      // Add transfers to payload
      if (this.form.nicknames) {
        this.form.nicknames.forEach((element, index) => {
          var transferObject = {
            source: element.id,
            amount: element.balance.value,
            target: this.showSelectedWalletByNetworkCurrency(element.currency.code)
          }

          payload.append('transfers[' + index + '][source]', transferObject.source);
          payload.append('transfers[' + index + '][target]', transferObject.target);
          payload.append('transfers[' + index + '][amount]', transferObject.amount);
        })
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/bankrollIncrementCreate', payload);

			// Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {
        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances();

        let id = this.selectedRequest.id;
        let url = Vue.prototype.$tab_slider.tabs.requests + id;

        // Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de depósito criado com sucesso.',
          color: 'success'
        });
        
        // Change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);
       
        // change to operation single view
        this.$router.push({ path: url });
      }
    },

    resetForm() {
      Object.assign(this.$data, this.initialState());
      this.$refs.formBankrollIncrementChild.reset();
    },

    handleNetworksFilter() { 
      let premiumNetworksIDs = [
        70, // Luxon USD
        71, // Luxon EUR
        79, // Luxon USDT
        73, // ChampionPoker EUR
        40, // Coinbase
        48, // Coinbase USD
        60, // Coinbase EUR
        22, // Bodog.com
        76, // MuchBetter USD
        77, // MuchBetter EUR
        66, // YaPoker
        36, // Eco Payz USD
        37, // Eco Payz EUR
      ];

      let filterNetworks = this.networks
      .map(network => network.id)
        .filter(id => !premiumNetworksIDs.includes(id));
      
      return filterNetworks;
    }
  }
}
import Vue from 'vue';

// 'DEV' / 'PROD'
Vue.prototype.$env = 'PROD';

// DEV
Vue.prototype.$polarize_site = 'http://localhost:8081';  // No trailing slash
Vue.prototype.publicPath = '/';

/* Vue.prototype.$url_api = 'http://playertracker:8080/api/';
Vue.prototype.$url_form_uploads = 'http://polarizepoker:8080/wp-content/uploads/tmp/';
//Vue.prototype.$url_auth_validate_jwt_token = 'http://polarizepoker:8080/wp-json/simple-jwt-authentication/v1/token/validate';
//Vue.prototype.$url_auth_get_jwt_token = 'http://polarizepoker:8080/wp-json/simple-jwt-authentication/v1/token';
Vue.prototype.$url_auth_validate_jwt_token = 'http://playertracker:8080/api/v2/auth/validate';
Vue.prototype.$url_auth_get_jwt_token = 'http://playertracker:8080/api/v2/auth';
Vue.prototype.$x_api_key = 'A:$3+Aj5eUSB2M}c';
Vue.prototype.$scheduling_api = 'http://localhost:3000/schedulingModels/';
Vue.prototype.$calendar_api = 'http://localhost:3000/';
Vue.prototype.$ctms_api = 'http://localhost:3001/completedTournamentsMS/'; */

/* Vue.prototype.$url_api = 'https://s01-tracker.polarize.poker/api/';
Vue.prototype.$url_form_uploads = 'https://s01.polarize.poker/wp-content/uploads/tmp/';
//Vue.prototype.$url_auth_validate_jwt_token = 'https://s01.polarizepoker.com.br/wp-json/simple-jwt-authentication/v1/token/validate';
//Vue.prototype.$url_auth_get_jwt_token = 'https://s01.polarizepoker.com.br/wp-json/simple-jwt-authentication/v1/token';
Vue.prototype.$url_auth_validate_jwt_token = 'https://s01-tracker.polarize.poker/api/v2/auth/validate';
Vue.prototype.$url_auth_get_jwt_token = 'https://s01-tracker.polarize.poker/api/v2/auth';
Vue.prototype.$x_api_key = 'A:$3+Aj5eUSB2M}c';
Vue.prototype.$scheduling_api = 'https://analysisscheduling.dq1.i234.me/schedulingModels/';
Vue.prototype.$calendar_api = 'https://calendar.dq1.i234.me/';
Vue.prototype.$ctms_api = 'http://161.230.84.234:3000/completedTournamentsMS/';
Vue.prototype.$url_api_live = 'https://search-staging-api.polarize.poker/'; */

Vue.prototype.$url_api = 'https://tracker.polarize.poker/api/';
Vue.prototype.$url_form_uploads = 'https://polarize.poker/wp-content/uploads/tmp/';
Vue.prototype.$url_auth_validate_jwt_token = 'https://tracker.polarize.poker/api/v2/auth/validate';
Vue.prototype.$url_auth_get_jwt_token = 'https://tracker.polarize.poker/api/v2/auth';
Vue.prototype.$x_api_key = 'A:$3+Aj5eUSB2M}c';
Vue.prototype.$scheduling_api = 'https://analysisscheduling.dq1.i234.me/schedulingModels/';
Vue.prototype.$calendar_api = 'https://calendar.dq1.i234.me/';
Vue.prototype.$ctms_api = 'https://ctms.dq1.i234.me/completedTournamentsMS/';
Vue.prototype.$url_api_live = 'https://search-api.polarize.poker/';

Vue.prototype.scheduling_configs = {
    timeBetweenReq: 15000
}

// 'DEV' / 'PROD'
Vue.prototype.$env = 'PROD';

// 'DEV' = user id 1  / 'PROD' = user id 115
Vue.prototype.$user_admin_id = 115;

Vue.prototype.$showUserLevelChangeProgress = false;

Vue.prototype.$cookie_names = {
    prefix: 'tracker_',
    jwt_token: 'tracker_jwt_token',
    dark_mode: 'tracker_dark_mode',
    group_level: 'tracker_group_level',
};

Vue.prototype.$rail_external_networks = [41,52,56,59];


// Cookies that wont be cleaned on logout
Vue.prototype.$cookie_whitelist = ['dark_mode'];

Vue.prototype.$links = {
    login_recover_password: 'https://polarizepoker.com.br/wp-login.php?action=lostpassword',
    player_single_page: '/players/:id',
    discord_schedule_duvidas: 'https://discord.com/channels/261570590178017280/707552606989582358',
};

Vue.prototype.$form_application = {
    cash: [
        'https://polarize.poker/candidatura/',
        'https://polarize.poker/candidatura-fase-2-cash/',
        'https://polarize.poker/candidatura-cash-fase-3/'
    ],
    mtt: [
        'https://polarize.poker/candidatura/',
        'https://polarize.poker/candidatura-fase-2-mtt/',
        'https://polarize.poker/candidatura-fase-3/'
    ],
    spins: [
        'https://polarize.poker/candidatura/',
        'https://polarize.poker/candidatura-fase-2-spins/',
        'https://polarize.poker/candidatura-fase-3/'
    ],

};

Vue.prototype.$team_ids = {
    mtt: '1',
    mtt_academy: '3',
    cash: ['5','6','7','8'],
    division_mtt: 10,
    division_cash: 11,
    division_spins: 13,
};

Vue.prototype.$video_requests = {
    status: {
        pending:  0,
        approved: 1,
        rejected: 2,
        expired:  3,
        complete: 4
    }
};

Vue.prototype.$video_reports = {
    base_url: Vue.prototype.$url_api + 'v1/files/uploads/video_reports/',
    status: {
        pending:  0,
        approved: 1
    }
}

Vue.prototype.$tab_slider = {
    tabs: {
        overview: '/rail/',
        requests: '/rail/operations/',
        sessions: '/rail/sessions/',
        transfers: '/rail/transfers/',
        swaps: '/rail/swaps/',
        reports: '/rail/reports/',
        expenses: '/rail/expenses/',
        statistics: '/rail/statistics/',
        action: '/rail/action/',
    }
},

Vue.prototype.$metadata = {
    Easy: { '@id': 'Easy', '@name': 'Easy Tournament', '@type': 'Difficulty' },
    Neutral: { '@id': 'Neutral', '@name': 'Neutral Tournament', '@type': 'Difficulty' },
    Hard: { '@id': 'Hard', '@name': 'Hard Tournament', '@type': 'Difficulty' },
    DM: { '@id': 'DM', '@description': 'A deal was made in this tournament.', '@name': 'Deal Made', '@type': 'Flag' },
    FO: { '@id': 'FO', '@description': 'All in initially and the survivors play for the money. ', '@name': 'Flipout', '@type': 'Format' },
    FPP: { '@id': 'FPP', '@description': 'The tournament buy in is with frequent player points.', '@name': 'FPP', '@type': 'Format' },
    ME: { '@id': 'ME', '@description': 'Players can register more than once and play multiple tables.', '@name': 'Multi Entry', '@type': 'Format' },
    NS: { '@id': 'NS', '@description': 'Players can choose when to use each of their stacks in the tournament.', '@name': 'N-Stack', '@type': 'Format' },
    OD: { '@id': 'OD', '@description': 'Sit & Gos with Late Registration.', '@name': 'OnDemand', '@type': 'Format' },
    R: { '@id': 'R', '@description': 'The tournament allows rebuys or add-ons.', '@name': 'Rebuy', '@type': 'Format' },
    RH: { '@id': 'RH', '@description': 'When players fold, they are transferred to a new table and dealt a new hand.', '@name': 'Rush', '@type': 'Format' },
    SO: { '@id': 'SO', '@description': 'Only the winner of each table progresses.', '@name': 'Shootout', '@type': 'Format' },
    '10G': { '@id': '10G', '@name': '10-Game', '@type': 'Game' },
    SD27L: { '@id': 'SD27L', '@name': '2-7 Single Draw', '@type': 'Game' },
    TD27L: { '@id': 'TD27L', '@name': '2-7 Triple draw', '@type': 'Game' },
    '32D': { '@id': '32D', '@name': '32 Draw', '@type': 'Game' },
    '5CD': { '@id': '5CD', '@name': '5 Card Draw', '@type': 'Game' },
    '5CS': { '@id': '5CS', '@name': '5 Card Stud', '@type': 'Game' },
    '5G': { '@id': '5G', '@name': '5-Game', '@type': 'Game' },
    '7CS': { '@id': '7CS', '@name': '7 Card Stud', '@type': 'Game' },
    '7CSHL': { '@id': '7CSHL', '@name': '7 Card Stud H/L', '@type': 'Game' },
    '7G': { '@id': '7G', '@name': '7-Game', '@type': 'Game' },
    '8G': { '@id': '8G', '@name': '8-Game', '@type': 'Game' },
    A: { '@id': 'A', '@name': 'Americana', '@type': 'Game' },
    DRAW: { '@children': '5CD,BA,TD27L,SD27L,32D', '@id': 'DRAW', '@name': 'Any Draw', '@type': 'Game' },
    MIXED: { '@children': 'HORSE,HEROS,HOSE,RASH,HA,HAR,SHOE,TE,8G,7G,HO', '@id': 'MIXED', '@name': 'Any Mixed', '@type': 'Game' },
    OMAHA: { '@children': 'O,OHL,O5,OHL5,CL,CLHL,FU,SHO', '@id': 'OMAHA', '@name': 'Any Omaha', '@type': 'Game' },
    STUD: { '@children': '7CS,7CSHL,5CS,RAZZ,A,S,TS', '@id': 'STUD', '@name': 'Any Stud', '@type': 'Game' },
    BA: { '@id': 'BA', '@name': 'Badugi', '@type': 'Game' },
    CL: { '@id': 'CL', '@name': 'Courchevel', '@type': 'Game' },
    CLHL: { '@id': 'CLHL', '@name': 'Courchevel H/L', '@type': 'Game' },
    CP: { '@id': 'CP', '@name': 'Crazy Pineapple', '@type': 'Game' },
    FU: { '@id': 'FU', '@name': 'Fusion', '@type': 'Game' },
    HA: { '@id': 'HA', '@name': 'HA', '@type': 'Game' },
    HAR: { '@id': 'HAR', '@name': 'HAR', '@type': 'Game' },
    HEROS: { '@id': 'HEROS', '@name': 'HEROS', '@type': 'Game' },
    HO: { '@id': 'HO', '@name': 'HO', '@type': 'Game' },
    H: { '@id': 'H', '@name': "Hold'em", '@type': 'Game' },
    HBJ: { '@id': 'HBJ', '@name': 'Holdem BJ', '@type': 'Game' },
    HORSE: { '@id': 'HORSE', '@name': 'HORSE', '@type': 'Game' },
    HOSE: { '@id': 'HOSE', '@name': 'HOSE', '@type': 'Game' },
    I: { '@id': 'I', '@name': 'Irish Holdem', '@type': 'Game' },
    OHL: { '@id': 'OHL', '@name': 'Omaha H/L', '@type': 'Game' },
    O: { '@id': 'O', '@name': 'Omaha Hi', '@type': 'Game' },
    O5: { '@id': 'O5', '@name': 'Omaha5', '@type': 'Game' },
    OHL5: { '@id': 'OHL5', '@name': 'Omaha5 H/L', '@type': 'Game' },
    O6: { '@id': 'O6', '@name': 'Omaha6', '@type': 'Game' },
    POFC: { '@id': 'POFC', '@name': 'Pineapple Open Face Chinese', '@type': 'Game' },
    RASH: { '@id': 'RASH', '@name': 'RASH', '@type': 'Game' },
    RAZZ: { '@id': 'RAZZ', '@name': 'Razz', '@type': 'Game' },
    RV: { '@id': 'RV', '@name': 'Reverse Holdem', '@type': 'Game' },
    SHOE: { '@id': 'SHOE', '@name': 'SHOE', '@type': 'Game' },
    SH: { '@id': 'SH', '@name': 'Showtime Holdem', '@type': 'Game' },
    SHO: { '@id': 'SHO', '@name': 'Showtime Omaha', '@type': 'Game' },
    H6: { '@id': 'H6', '@name': 'Six-Plus Holdem', '@type': 'Game' },
    S: { '@id': 'S', '@name': 'Soko', '@type': 'Game' },
    TE: { '@id': 'TE', '@name': 'Telesina', '@type': 'Game' },
    TS: { '@id': 'TS', '@name': 'Triple Stud', '@type': 'Game' },
    B: { '@id': 'B', '@description': 'Additional prizes are given for knocking players out. Also known as Knockout, Hitman and HeadHunter tournaments.', '@name': 'Bounty', '@type': 'PrizeStructure' },
    C: { '@id': 'C', '@description': 'Players can cash in their tournament chips at any time.', '@name': 'Cashout', '@type': 'PrizeStructure' },
    DN: { '@id': 'DN', '@description': 'Half of the players double their buy in.', '@name': 'Double or Nothing', '@type': 'PrizeStructure' },
    F: { '@id': 'F', '@description': 'Half the field paid based on chip counts.', '@name': 'Fifty50', '@type': 'PrizeStructure' },
    HIT: { '@id': 'HIT', '@description': 'Passing a chip threshold instantly awards a ticket', '@name': 'Hit&Run', '@type': 'PrizeStructure' },
    J: { '@id': 'J', '@description': 'Additional rake goes towards a grand prize.', '@name': 'Jackpot', '@type': 'PrizeStructure' },
    K: { '@id': 'K', '@description': 'No prizepool just a full bounty on each player.', '@name': 'Killer', '@type': 'PrizeStructure' },
    M: { '@id': 'M', '@description': '4 simultaneous Sit&Goes against the same players, with additional prizes for overall performances.', '@name': 'Matrix', '@type': 'PrizeStructure' },
    SAT: { '@id': 'SAT', '@description': 'Prize is an entry into another tournament.', '@name': 'Satellite', '@type': 'PrizeStructure' },
    TR: { '@id': 'TR', '@description': 'The prize is a ticket to the next tournament tier. Also known as Step tournaments.', '@name': 'Tiered', '@type': 'PrizeStructure' },
    TI: { '@id': 'TI', '@description': 'The tournament finishes at a set time.', '@name': 'Timed', '@type': 'PrizeStructure' },
    TN: { '@id': 'TN', '@description': '1/3rd of the players triple their buy in.', '@name': 'Triple or Nothing', '@type': 'PrizeStructure' },
    W: { '@id': 'W', '@description': '1st place wins the entire prize pool.', '@name': 'Winner Takes All', '@type': 'PrizeStructure' },
    D: { '@id': 'D', '@description': 'Starting stacks are larger than normal.', '@name': 'Deep Stack', '@type': 'Speed' },
    L: { '@id': 'L', '@description': 'All players are automatically put All In at the start.', '@name': 'Lottery', '@type': 'Speed' },
    NBI: { '@id': 'NBI', '@description': 'Static blind levels for the entirety of the tournament.', '@name': 'No Blind Increases', '@type': 'Speed' },
    N: { '@id': 'N', '@description': 'Blinds level increase at normal rate.', '@name': 'Normal', '@type': 'Speed' },
    ST: { '@id': 'ST', '@description': 'Blind levels increase faster than in a Turbo tournament.', '@name': 'Super Turbo', '@type': 'Speed' },
    T: { '@id': 'T', '@description': 'Blinds level increase faster than normal.', '@name': 'Turbo', '@type': 'Speed' },
    FL: { '@id': 'FL', '@name': 'Limit', '@type': 'Structure' },
    ML: { '@id': 'ML', '@name': 'Mixed Limit', '@type': 'Structure' },
    NL: { '@id': 'NL', '@name': 'No Limit', '@type': 'Structure' },
    NLH: { '@id': 'NLH', '@name': "Hold'em, No Limit", '@type': 'Structure' },
    PNL: { '@id': 'PNL', '@name': 'PNL', '@type': 'Structure' },
    PL: { '@id': 'PL', '@name': 'Pot Limit', '@type': 'Structure' },
    SL: { '@id': 'SL', '@name': 'Spread Limit', '@type': 'Structure' },
    '6MX': { '@id': '6MX', '@description': '6 players per table', '@name': '6 Max', '@type': 'TableSize' },
    HU: { '@id': 'HU', '@description': '2 players per table.', '@name': 'Heads Up', '@type': 'TableSize' }
  };

Vue.prototype.$rail_ops = {
    operations: {
        split: 1,
        bankroll_reduction: 2,
        bankroll_increment: 3,
        transfer_to_self: 4,
        swap: 5,
        deposit: 6,
        withdraw: 7,
        sessions: 8,
        makeup: 9,
        reports: 10,
        any: 11,
        transfer_to_player: 12,
    }
}

// Positions according to Vue.prototype.$rail_ops.<OPERATION>
Vue.prototype.$operationColor = [
    '',
    'blue',    // SPLIT
    'orange',  // BANKROLL_REDUCTION
    'green',   // BANKROLL_INCREMENT
    '',        // TRANSFER
    '',        // SWAP
    'accent',  // DEPOSIT
    'purple',  // WITHDRAW
    'grey',     // SESSION
    'cyan'      // RAKE
]

Vue.prototype.$rail_status = {
    pending: 1,
    accepted: 2,
    approved: 3,
    rejected: 4,
    finalized: 5,
}

Vue.prototype.$rail_status_name = [
    'Pendente',
    'Aceite',
    'Aprovado',
    'Rejeitado',
    'Finalizado'
]

Vue.prototype.$direction_rail_operations = {
    increase: 1,
    decrease: 2,
}

Vue.prototype.$rail_reports = {
    status: {
        ongoing: 1,
        seen: 2,
        resolved: 3
    }
}

Vue.prototype.$transfer_types = {
    PNPW: 1,
    PWPN: 2,
    P_P: 3, // transfer types from P1 -> P2 
    P_M: 4, // transfer types from P -> Management
    P_O: 5, // transfer types from P -> Outside
    M_P: 6, // transfer types from Management -> Player
    M_M: 7, // transfer types from Management -> Management
    M_O: 8, // transfer types from Management -> Outside
    O_M: 9, // transfer types from Outside -> Management
    UNKNOWN: 10 // transfer types that shouldnt exists are stored as UNKNOWN
}

/**
 * Define all valid user groups for progress calculation purposes.
 * Min = Nivel 1.1
 * Max = Nivel 6
 */
Vue.prototype.$user_groups = {
    min: 2,
    max: 18
}

Vue.prototype.$teams_types = {
    real: 1,
    helper: 2
}

Vue.prototype.$currency_ids = {
    UNKNOWN: 1,
    EUR: 2,
    USD: 3,
    GBP: 4,
    CNY: 5,
    VND: 6,
    BTC: 7,
    PHP: 8,
    HKD: 9,
    TWD: 10,
    KRW: 11,
    JPY: 12,
};

Vue.prototype.$currency_default_precision = 2;

Vue.prototype.$currencies = [
    { id: Vue.prototype.$currency_ids.UNKNOWN, name: 'Desconhecido', symbol: '?', code: 'UNKNOWN', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.EUR, name: 'Euro', symbol: '€', code: 'EUR', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.USD, name: 'Dollar', symbol: '$', code: 'USD', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.GBP, name: 'Libra', symbol: '£', code: 'GBP', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.CNY, name: 'Chinese Yuan', symbol: '¥', code: 'CNY', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.VND, name: 'Vietnamese Dong', symbol: '₫', code: 'VND', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.BTC, name: 'Bitcoin', symbol: '₿', code: 'BTC', precision: 8 },
    { id: Vue.prototype.$currency_ids.PHP, name: 'Philippine Peso', symbol: '₱', code: 'PHP', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.HKD, name: 'Hong Kong Dollar', symbol: 'HK$', code: 'HKD', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.TWD, name: 'New Taiwan Dollar', symbol: 'NT$', code: 'TWD', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.KRW, name: 'Korean Won', symbol: '₩', code: 'KRW', precision: Vue.prototype.$currency_default_precision },
    { id: Vue.prototype.$currency_ids.JPY, name: 'Japanese Yen', symbol: 'JP¥', code: 'JPY', precision: Vue.prototype.$currency_default_precision },
],

Vue.prototype.$nickname_status = {
    active: { value: 1, name: 'OK' },
    flagged: { value: 2, name: 'Não encontrado' },
    deassociated: { value: 3, name: 'Não associado' },
    inactive: { value: 4, name: 'Inativo' },
};

Vue.prototype.$user_status = [
    { value: 1, name: 'Inativo', icon: 'mdi-close-circle-outline' },
    { value: 2, name: 'Ativo', icon: 'mdi-check-circle-outline' },
    { value: 3, name: 'No Stats',  icon: 'mdi-help-circle-outline' },
    { value: 4, name: 'Application', icon: 'mdi-help-circle-outline' },
];

Vue.prototype.$gamification_session_dialog_feedback_time = 0;   // Close DialogSessionGamification after x ms
Vue.prototype.$gamification_session_animation_time = 4000;      // Animation lasts x ms
Vue.prototype.$team_division_mtt_id = 10;
Vue.prototype.$team_division_cash_id = 11;
Vue.prototype.$team_division_spins_id = 13;
Vue.prototype.$rail_YaPoker_network_id = 66;
Vue.prototype.$rail_Coinbase_network_btc_id = 40;
Vue.prototype.$rail_liveUSD_network_id = 42;
Vue.prototype.$rail_liveEUR_network_id = 43;
Vue.prototype.$rail_RakeEUR_network_id = 44;
Vue.prototype.$rail_RakeUSD_network_id = 45;
Vue.prototype.$rail_GGPoker_network_id = 17;
Vue.prototype.$network_Sharkscope_GGPoker_id = 12;
Vue.prototype.$rail_external_wallet_id = 0;
Vue.prototype.$rail_skrill_usd_network = 34;
Vue.prototype.$rail_muchbetter_usd_network = 76;
Vue.prototype.$rail_muchbetter_eur_network = 77;
Vue.prototype.$default_currency = 'EUR';
Vue.prototype.$default_currency_icon = 'mdi-currency-eur';
Vue.prototype.$crypto_currencies = [
    'BTC'
]
// Networks with Backoffice - Used in  UC's - Split and BankrollReduction
Vue.prototype.$rail_networks_with_Backoffice = [
    Vue.prototype.$rail_YaPoker_network_id,
    Vue.prototype.$rail_GGPoker_network_id
]

/**
 * Table requests sides - 'phase,type' strings indicating an operation is PENDING on the user/admin side.
 * $table_requests_pending['admin'][usecase][phase][status]
 * 
 * TODO: Swap
 */

Vue.prototype.$table_requests_pending = {
    admin: [],
    user: [],
};

Vue.prototype.$table_requests_pending['admin'][Vue.prototype.$rail_ops.operations.split] = ['1,1', '3,3'];
Vue.prototype.$table_requests_pending['admin'][Vue.prototype.$rail_ops.operations.bankroll_reduction] = ['2,1', '2,3'];
Vue.prototype.$table_requests_pending['admin'][Vue.prototype.$rail_ops.operations.bankroll_increment] = ['1,1'];

Vue.prototype.$table_requests_pending['user'][Vue.prototype.$rail_ops.operations.split] = ['2,2', '2,3'];
Vue.prototype.$table_requests_pending['user'][Vue.prototype.$rail_ops.operations.bankroll_reduction] = ['1,3'];
Vue.prototype.$table_requests_pending['user'][Vue.prototype.$rail_ops.operations.swap] = ['1,1'];

/**
 * Table requests phases
 */

Vue.prototype.table_requests_phases = [];

Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.split] = [
    { value: 1, name: 'Pedido' },
    { value: 2, name: 'Aprovado' },
    { value: 3, name: 'Transferido' },
    { value: 4, name: 'Confirmado' },
];

Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.bankroll_reduction] = [
    { value: 1, name: 'Pedido' },
    { value: 2, name: 'Transferido' },
    { value: 3, name: 'Confirmado' },
];

Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.bankroll_increment] = [
    { value: 1, name: 'Pedido' },
    { value: 2, name: 'Transferido' },
];

Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.transfer_to_self] = [
    { value: 1, name: 'Transferido' },
    { value: 2, name: 'Recebido' },
];

Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.transfer_to_player] = [
    { value: 1, name: 'Transferido' },
    { value: 2, name: 'Recebido' },
];

Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.deposit] = [
    { value: 1, name: 'Transferido' },
    { value: 2, name: 'Recebido' },
];

Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.withdraw] = [
    { value: 1, name: 'Transferido' },
    { value: 2, name: 'Recebido' },
];

Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.swap] = [
    { value: 1, name: 'Transferido' },
    { value: 2, name: 'Confirmado' },
];

Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.makeup] = [
    { value: 1, name: 'Finalizado' },
];

Vue.prototype.$system_event_types = [
    { id: 1, icon: 'mdi-account-plus-outline', code_name: 'USER_CREATE' },
    { id: 2, icon: 'mdi-account-edit', code_name: 'USER_UPDATE' },
    { id: 3, icon: 'mdi-account-minus-outline', code_name: 'USER_DELETE' },
    { id: 4, icon: 'mdi-restore', code_name: 'USER_RESTORE' },
    { id: 5, icon: 'mdi-account-multiple-check-outline', code_name: 'USER_GROUP_CHANGE' },
    { id: 6, icon: 'mdi-account-multiple-plus-outline', code_name: 'USER_TEAM_ADD' },
    { id: 7, icon: 'mdi-account-multiple-remove-outline', code_name: 'USER_TEAM_REMOVE' },
    { id: 8, icon: 'mdi-progress-upload', code_name: 'USER_TEAM_PROMOTION' },
    { id: 9, icon: 'mdi-progress-download', code_name: 'USER_TEAM_DEMOTION' },
    { id: 10, icon: 'mdi-checkbox-marked-circle-outline', code_name: 'USER_TYPE_CREATE' },
    { id: 11, icon: 'mdi-circle-edit-outline', code_name: 'USER_TYPE_UPDATE' },
    { id: 12, icon: 'mdi-delete-outline', code_name: 'USER_TYPE_DELETE' },
    { id: 13, icon: 'mdi-restore', code_name: 'USER_TYPE_RESTORE' },
    { id: 14, icon: 'mdi-account-box-outline', code_name: 'NICKNAME_CREATE' },
    { id: 15, icon: 'mdi-circle-edit-outline', code_name: 'NICKNAME_UPDATE' },
    { id: 16, icon: 'mdi-delete-outline', code_name: 'NICKNAME_DELETE' },
    { id: 17, icon: 'mdi-restore', code_name: 'NICKNAME_RESTORE' },
    { id: 18, icon: 'mdi-bank-plus', code_name: 'NETWORK_CREATE' },
    { id: 19, icon: 'mdi-bank-check', code_name: 'NETWORK_UPDATE' },
    { id: 20, icon: 'mdi-bank-minus', code_name: 'NETWORK_DELETE' },
    { id: 21, icon: 'mdi-restore', code_name: 'NETWORK_RESTORE' },
    { id: 22, icon: 'mdi-plus-circle-outline', code_name: 'TEAM_CREATE' },
    { id: 23, icon: 'mdi-circle-edit-outline', code_name: 'TEAM_UPDATE' },
    { id: 24, icon: 'mdi-delete-outline', code_name: 'TEAM_DELETE' },
    { id: 25, icon: 'mdi-restore', code_name: 'TEAM_RESTORE' },
    { id: 26, icon: 'mdi-plus-circle-outline', code_name: 'GROUP_CREATE' },
    { id: 27, icon: 'mdi-circle-edit-outline', code_name: 'GROUP_UPDATE' },
    { id: 28, icon: 'mdi-delete-outline', code_name: 'GROUP_DELETE' },
    { id: 29, icon: 'mdi-restore', code_name: 'GROUP_RESTORE' },
    { id: 30, icon: 'mdi-movie-open-plus-outline', code_name: 'VIDEO_REQUEST_CREATE' },
    { id: 31, icon: 'mdi-movie-open-check-outline', code_name: 'VIDEO_REQUEST_UPDATE' },
    { id: 32, icon: 'mdi-movie-open-remove-outline', code_name: 'VIDEO_REQUEST_DELETE' },
    { id: 33, icon: 'mdi-restore', code_name: 'VIDEO_REQUEST_RESTORE' },
    { id: 34, icon: 'mdi-file-video-outline', code_name: 'VIDEO_REPORT_CREATE' },
    { id: 35, icon: 'mdi-file-video-outline', code_name: 'VIDEO_REPORT_UPDATE' },
    { id: 36, icon: 'mdi-delete-outline', code_name: 'VIDEO_REPORT_DELETE' },
    { id: 37, icon: 'mdi-restore', code_name: 'VIDEO_REPORT_RESTORE' },
    { id: 38, icon: 'mdi-plus-circle-outline', code_name: 'CURRENCY_CREATE' },
    { id: 39, icon: 'mdi-restore', code_name: 'CURRENCY_UPDATE' },
    { id: 40, icon: 'mdi-delete-outline', code_name: 'CURRENCY_DELETE' },
    { id: 41, icon: 'mdi-restore', code_name: 'CURRENCY_RESTORE' },
    { id: 42, icon: 'mdi-clipboard-account-outline', code_name: 'APPLICATION_CREATE' },
    { id: 43, icon: 'mdi-clipboard-edit-outline', code_name: 'APPLICATION_UPDATE' },
    { id: 44, icon: 'mdi-clipboard-remove-outline', code_name: 'APPLICATION_DELETE' },
    { id: 45, icon: 'mdi-clipboard-clock-outline', code_name: 'APPLICATION_RESTORE' },
    { id: 46, icon: 'mdi-clipboard-plus-outline', code_name: 'APPLICATION_CATEGORY_CREATE' },
    { id: 47, icon: 'mdi-clipboard-edit-outline', code_name: 'APPLICATION_CATEGORY_UPDATE' },
    { id: 48, icon: 'mdi-clipboard-minus-outline', code_name: 'APPLICATION_CATEGORY_DELETE' },
    { id: 49, icon: 'mdi-restore', code_name: 'APPLICATION_CATEGORY_RESTORE' },
    { id: 50, icon: 'mdi-plus-circle-outline', code_name: 'APPLICATION_REJECTION_REASONS_CREATE' },
    { id: 51, icon: 'mdi-circle-edit-outline', code_name: 'APPLICATION_REJECTION_REASONS_UPDATE' },
    { id: 52, icon: 'mdi-delete-outline', code_name: 'APPLICATION_REJECTION_REASONS_DELETE' },
    { id: 53, icon: 'mdi-restore', code_name: 'APPLICATION_REJECTION_REASONS_RESTORE' },
    { id: 54, icon: 'mdi-plus-circle-outline', code_name: 'DEPARTURE_CREATE' },
    { id: 55, icon: 'mdi-circle-edit-outline', code_name: 'DEPARTURE_UPDATE' },
    { id: 56, icon: 'mdi-delete-outline', code_name: 'DEPARTURE_DELETE' },
    { id: 57, icon: 'mdi-restore', code_name: 'DEPARTURE_RESTORE' },
    { id: 58, icon: 'mdi-plus-circle-outline', code_name: 'DEPARTURE_REASONS_CREATE' },
    { id: 59, icon: 'mdi-circle-edit-outline', code_name: 'DEPARTURE_REASONS_UPDATE' },
    { id: 60, icon: 'mdi-delete-outline', code_name: 'DEPARTURE_REASONS_DELETE' },
    { id: 61, icon: 'mdi-restore', code_name: 'DEPARTURE_REASONS_RESTORE' },
    { id: 62, icon: 'mdi-file-plus-outline', code_name: 'RAIL_OPERATION_CREATE' },
    { id: 63, icon: 'mdi-file-edit-outline', code_name: 'RAIL_OPERATION_UPDATE' },
    { id: 64, icon: 'mdi-delete-outline', code_name: 'RAIL_OPERATION_DELETE' },
    { id: 65, icon: 'mdi-restore', code_name: 'RAIL_OPERATION_RESTORE' },
    { id: 66, icon: 'mdi-note-remove-outline', code_name: 'RAIL_OPERATION_REJECT' },
    { id: 67, icon: 'mdi-plus-circle-outline', code_name: 'RAIL_EXPENSE_TYPE_CREATE' },
    { id: 68, icon: 'mdi-file-edit-outline', code_name: 'RAIL_EXPENSE_TYPE_UPDATE' },
    { id: 69, icon: 'mdi-delete-outline', code_name: 'RAIL_EXPENSE_TYPE_DELETE' },
    { id: 70, icon: 'mdi-plus-circle-outline', code_name: 'RAIL_REPORT_CREATE' },
    { id: 71, icon: 'mdi-file-document-edit-outline', code_name: 'RAIL_REPORT_UPDATE' },
    { id: 72, icon: 'mdi-delete-outline', code_name: 'RAIL_REPORT_DELETE' },
    { id: 73, icon: 'mdi-file-restore-outline', code_name: 'RAIL_REPORT_RESTORE' },
    { id: 74, icon: 'mdi-plus-circle-outline', code_name: 'RAIL_REPORT_TYPE_CREATE' },
    { id: 75, icon: 'mdi-file-edit-outline', code_name: 'RAIL_REPORT_TYPE_UPDATE' },
    { id: 76, icon: 'mdi-delete-outline', code_name: 'RAIL_REPORT_TYPE_DELETE' },
    { id: 77, icon: 'mdi-restore', code_name: 'RAIL_REPORT_TYPE_RESTORE' },
    { id: 78, icon: 'mdi-calendar', code_name: 'SCHEDULE_CREATE' },
    { id: 79, icon: 'mdi-calendar-edit', code_name: 'SCHEDULE_UPDATE' },
    { id: 80, icon: 'mdi-calendar-remove', code_name: 'SCHEDULE_DELETE' },
    { id: 81, icon: 'mdi-calendar-refresh', code_name: 'SCHEDULE_RESTORE' },
    { id: 82, icon: 'mdi-alert-outline', code_name: 'ANALYSIS_ANOMALY' },
    { id: 83, icon: 'mdi-message-alert-outline', code_name: 'ANALYSIS_FLAG' },
    { id: 84, icon: 'mdi-plus-circle-outline', code_name: 'AUDIT_CREATE' },
    { id: 85, icon: 'mdi-file-edit-outline', code_name: 'AUDIT_UPDATE' },
    { id: 86, icon: 'mdi-delete-outline', code_name: 'AUDIT_DELETE' },
    { id: 87, icon: 'mdi-restore', code_name: 'AUDIT_RESTORE' },
    { id: 88, icon: 'mdi-file-document-outline', code_name: 'CONTRACT_CREATE' },
    { id: 89, icon: 'mdi-file-document-edit-outline', code_name: 'CONTRACT_UPDATE' },
    { id: 90, icon: 'mdi-file-remove-outline', code_name: 'CONTRACT_DELETE' },
    { id: 91, icon: 'mdi-file-undo-outline', code_name: 'CONTRACT_RESTORE' },
    { id: 92, icon: 'mdi-plus-circle-outline', code_name: 'GAME_CREATE' },
    { id: 93, icon: 'mdi-file-edit-outline', code_name: 'GAME_UPDATE' },
    { id: 94, icon: 'mdi-delete-outline', code_name: 'GAME_DELETE' },
    { id: 95, icon: 'mdi-restore', code_name: 'GAME_RESTORE' },
    { id: 96, icon: 'mdi-alert-circle-outline', code_name: 'RAIL_SESSION_ANOMALY' },
];

Vue.prototype.$groups = [
    { name: 'Sem grupo', image: 'nogroup.png' },
    { name: 'Nivel 1', image: 'mtt_1.png' },
    { name: 'Nivel 2', image: 'mtt_2.png' },
    { name: 'Nivel 3', image: 'mtt_3.png' },
    { name: 'Nivel 4', image: 'mtt_4.png' },
    { name: 'Nivel 5', image: 'mtt_5.png' },
    { name: 'Nivel 6', image: 'mtt_6.png' },
    { name: 'Nivel 7', image: 'mtt_7.png' },
    { name: 'Jogadores externos', image: 'external.png' },
    { name: 'Team Cash', image: 'cash.png' },
];

Vue.prototype.$group_badges = [
    { name: 'NL12', image: 'cash_nl2.png' },
    { name: 'NL5', image: 'cash_nl5.png' },
    { name: 'NL10', image: 'cash_nl10.png' },
    { name: 'NL25', image: 'cash_nl25.png' },
    { name: 'NL50', image: 'cash_nl50.png' },
    { name: 'NL100', image: 'cash_nl100.png' },
    { name: 'NL500-1000', image: 'cash_nl500-1000.png' },
    { name: 'NL500', image: 'cash_nl500.png' },
    { name: 'NL1000-2000', image: 'cash_nl1000-2000.png' },
    { name: 'NL1000', image: 'cash_nl1000.png' },
    { name: 'Cash', image: 'cash.png' },
    { name: 'Default', image: 'default.png' },
    { name: 'External', image: 'external.png' },
    { name: 'MTT 1.1', image: 'mtt_1.1.png' },
    { name: 'MTT 1.2', image: 'mtt_1.2.png' },
    { name: 'MTT 1.2', image: 'mtt_1.2.png' },
    { name: 'MTT 1.3', image: 'mtt_1.3.png' },
    { name: 'MTT 1.4', image: 'mtt_1.4.png' },
    { name: 'MTT 1.5', image: 'mtt_1.5.png' },
    { name: 'MTT 2.1', image: 'mtt_2.1.png' },
    { name: 'MTT 2.2', image: 'mtt_2.2.png' },
    { name: 'MTT 2.3', image: 'mtt_2.3.png' },
    { name: 'MTT 2.4', image: 'mtt_2.4.png' },
    { name: 'MTT 3.1', image: 'mtt_3.1.png' },
    { name: 'MTT 3.2', image: 'mtt_3.2.png' },
    { name: 'MTT 3.3', image: 'mtt_3.3.png' },
    { name: 'MTT 3.4', image: 'mtt_3.4.png' },
    { name: 'MTT 4.1', image: 'mtt_4.1.png' },
    { name: 'MTT 4.2', image: 'mtt_4.2.png' },
    { name: 'MTT 4.5', image: 'mtt_4.5.png' },
    { name: 'MTT 4.8', image: 'mtt_4.8.png' },
    { name: 'MTT 5.1', image: 'mtt_5.1.png' },
    { name: 'MTT 5.5', image: 'mtt_5.5.png' },
    { name: 'MTT 6.1', image: 'mtt_6.1.png' },
    { name: 'MTT 6.5', image: 'mtt_6.5.png' },
    { name: 'MTT 7.1', image: 'mtt_7.1.png' },
    { name: 'Mtt', image: 'mtt.png' },
    { name: 'Spins 1.5', image: 'spins_1.5.png' },
    { name: 'Spins 1', image: 'spins_1.png' },
    { name: 'Spins 2', image: 'spins_2.png' },
    { name: 'Spins 3.5', image: 'spins_3.5.png' },
    { name: 'Spins 3', image: 'spins_3.png' },
    { name: 'Spins 4', image: 'spins_4.png' },
    { name: 'Spins 5', image: 'spins_5.png' },
    { name: 'Spins 6.5', image: 'spins_6.5.png' },
    { name: 'Spins 6', image: 'spins_6.png' },
    { name: 'Spins 7', image: 'spins_7.png' },
    { name: 'Spins 8', image: 'spins_8.png' },
    { name: 'Spins 9', image: 'spins_9.png' },
    { name: 'Spins 10', image: 'spins_10.png' },
    { name: 'Spins 11', image: 'spins_11.png' },
    { name: 'Spins', image: 'spins.png' },
];

Vue.prototype.$GroupChangeStatus = [
    { key: 1, name: 'Pendente', color: 'accent', icon: 'mdi-account-clock-outline', textColor: 'black--text' },
    { key: 2, name: 'Aprovado', color: 'success', icon: 'mdi-checkbox-marked-outline', textColor: 'white--text' },
    { key: 3, name: 'Rejeitado', color: 'warning', icon: 'mdi-close-box-outline', textColor: 'white--text' },
];

Vue.prototype.$GroupChangeDirection = [
    { key: 1, name: 'Descida', icon: 'mdi-arrow-down-bold-circle-outline', color: 'error' },
    { key: 2, name: 'Subida', icon: 'mdi-arrow-up-bold-circle-outline', color: 'success'},
];

Vue.prototype.$GroupsChangeReason = [
    { key: 1, name: 'DOWN_ABI_DOWN', text: 'DOWN_ABI_DOWN', subtitle: 'Descida por average buys-in' },
    { key: 2, name: 'DOWN_DAYS_OFF', text: 'DOWN_DAYS_OFF', subtitle: 'Descida por dias off'},
    { key: 3, name: 'UP_DAYS_OFF', text: 'UP_DAYS_OFF', subtitle: 'Subida por dias off' },
    { key: 4, name: 'UP_SPLITS_NORMAL', text: 'UP_SPLITS_NORMAL', subtitle: 'Subida normal por splits' },
    { key: 5, name: 'UP_FAST_SPLITS', text: 'UP_FAST_SPLITS',  subtitle: 'Subida rápida por splits' },
    { key: 6, name: 'UP_RECOVERY_STATS_OK', text: 'UP_RECOVERY_STATS_OK', subtitle: 'Recuperação por stats ok' },
    { key: 7, name: 'UP_RECOVERY_STATS_NOK', text: 'UP_RECOVERY_STATS_NOK', subtitle: 'Recuperação por stats não ok' },
    { key: 8, name: 'UNKNOWN', text: 'UNKNOWN', subtitle: 'Desconhecido' },
];

Vue.prototype.$LevelChangeUserMode = [
    { key: 1, name: 'Manual' },
    { key: 2, name: 'Normal' },
    { key: 3, name: 'Swaps' },
];

Vue.prototype.$audits = {
    timeInterval: 300000, // 5min
    status: {
        REQUESTED: 1,
        PENDING_REVIEW: 2,
        APPROVED: 3,
        REJECTED: 4,
    }
}

Vue.prototype.$weekday = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado'
];

//  need to be in sync frontend <-> backend
Vue.prototype.defaultWhenEarlyCap = {
    start: '00:00:00',
    end: '10:00:00'
}

Vue.prototype.$external_wallet = {
    "id": Vue.prototype.$rail_external_wallet_id,
    "name": "In & Out Wallet",
    "status": 1,
    "balance": {
        "value": 0,
        "text": "UNKNOWN 0.00",
        "EUR": {
            "value": 0,
            "text": "EUR 0.00"
        },
        "USD": {
            "value": 0,
            "text": "USD 0.00"
        }
    },
    "isManagement": true,
    "isExternal": false,
    "network": {
        "id": 41,
        "name": "EXTERNAL",
        "identifier": null,
        "currency": {
            "id": 1,
            "code": "UNKNOWN",
            "symbol": "?",
            "rate": 1
        },
        "isWallet": true,
        "imageURL": "withdraw_lg.png",
        "nameLegacyRail": null
    },
}

Vue.prototype.scheduling_configs = {
    timeBetweenReq: 15000,
    gameFlags: [
        {
            name: "Allows reentries",
            code: "ME"
        },
        {
            name: "Allows rebuys/add-ons",
            code: "R"
        },
        {
            name: "KO Tournament",
            code: "B"
        },
        {
            name: "Satelite Tournament",
            code: "SAT"
        },
        {
            name: "Turbo Tournament",
            code: "T"
        },
        {
            name: "Super Turbo Tournament",
            code: "ST"
        },
        {
            name: "6 players per table",
            code: "6MX"
        },
    ]
}

Vue.prototype.$group_types = {
    level: 1,
    group: 2,
}

Vue.prototype.$statistics_name = {
    'CUMULATIVE_PNL': { name: 'PnL Cumulativo', description: 'O PnL cumulativo das sessões' },
    'IN_DEBT': { name: 'Em dívida', description: 'Montante em dívida' },
    'DEBIT': { name: 'DEBIT', description: 'Montante a receber das operações a decorrer/pendentes' },
    'SPLIT': { name: 'SPLIT', description: 'Montante total splitado' },
    'SPLIT_USER': { name: 'SPLIT Jogador', description: 'Montante total splitado pelo jogador' },
    'SPLIT_MANAGEMENT': { name: 'SPLIT Gestão', description: 'Montante total recebido pela gestão proveniente de splits.' },
    'PROFIT': { name: 'Lucro', description: 'Diferença entre a banca atual e a banca inicial do jogador. Se estiver a cima de 0 o jogador está com lucro, caso contrário o jogador está com makeup. Não é afetado pelo intervalo de datas!' },
    'SWAP': { name: 'SWAP', description: 'Diferença entre o swap enviado e o swap recebido' },
    'SWAP_SENT': { name: 'SWAP (Enviado)', description: 'Montante total enviado pelo jogador' },
    'SWAP_RECEIVED': { name: 'SWAP (Recebido)', description: 'Montante total recebido pelo jogador' },
    'BANKROLL_MANAGEMENT': { name: 'Banca atual', description: 'Banca atual da gestão' },
    'BANKROLL_USER': { name: 'Banca atual', description: 'Banca atual do jogador nos seus nicknames/carteiras.' },
    'CAPITAL_INFLOW': { name: 'Montante recebido', description: 'Montantes recebidos (SPLIT, REDUÇÃO DE BANCA E DEPÓSITOS)' },
    'BANKROLL_INCREMENT': { name: 'Incrementos de banca', description: 'Montante enviado pela gestão' },
    'BANKROLL_REDUCTION': { name: 'Reduções de banca', description: 'Montante recebido pela gestão' },
    'DEPOSIT': { name: 'Depósitos', description: 'Montantes depositados pela gestão' },
    'CAPITAL_OUTFLOW': { name: 'Montante levantado', description: 'Montante levantado pela gestão' },
    'WITHDRAW': { name: 'Levantamentos', description: 'Levantamento de montantes das carteiras da gestão' },
    'EXPENSE': { name: 'Despesas', description: 'Montante declarado como despesa' },
    'AVERAGE_PNL': { name: 'Média PnL', description: 'A média PnL calculado com base nas sessões' },
    'TOP_USERS_PNL': { name: 'TOP users PnL', description: 'Jogadores com as melhores sessões' },
    'BOT_USERS_PNL': { name: 'BOTTOM users PnL', description: 'Jogadores com as piores sessões' },
    'BANKROLL_INITIAL_USER': { name: 'Banca inicial', description: 'Montante inicial nos nicknames' },
    'EVENTS_COUNT': { name: 'N.º de eventos', description: 'Número de eventos (torneios ou mãos jogadas)' },
    'EVENTS_CONTRACT_COUNT': { name: 'N.º de eventos por contrato', description: 'Número de eventos (torneios ou mãos jogadas) durante o tempo de contrato' },
}

Vue.prototype.$deal_share_percentages = [
    { usersNumber: 2, share: '0.5000' },
    { usersNumber: 3, share: '0.1000' },
    { usersNumber: 4, share: '0.1000' },
    { usersNumber: 5, share: '0.2000' },
    { usersNumber: 6, share: '0.2000' },
    { usersNumber: 7, share: '0.2000' },
]
/**
 * Default options of items per page used in v-data-table
 * 
 * @var {array} itemsPerPageOptions 
 */
Vue.prototype.$itemsPerPageOptions = [10, 25, 50, 100, 200, -1];
